@tailwind base;
@tailwind components;
@tailwind utilities;

nav .nav-btn {
  @apply p-2 rounded bg-gray-200 m-3;
}

@layer components {
  .button {
    @apply bg-gray-400 text-white font-bold py-2 px-4 rounded border block mx-auto;
  }
  .cell-title {
    @apply p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell;
  }
  .cell-content {
    @apply w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static;
  }
}
